import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Handler } from '@digital-magic/ts-common-utils'
import { Button } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { CommonControlProps } from '@controls/types'
import { ActionButtonsContainer } from '@layout/ActionButtonsContainer'

export type SuggestVehicleFormControlsProps = Readonly<{
  onBtnSubmitClick: Handler<void>
  onBtnBackClick: Handler<void>
}> &
  CommonControlProps

export const SuggestVehicleFormControls: React.FC<SuggestVehicleFormControlsProps> = ({
  disabledControls,
  onBtnBackClick,
  onBtnSubmitClick
}) => {
  const { t } = useTranslation()

  return (
    <ActionButtonsContainer fixedDirection justifyContentSpaceBetween noTopGap>
      <Button variant="text" color="darkBlue" startIcon={<ArrowBackIcon />} onClick={() => onBtnBackClick()}>
        {t('global.buttons.contactDetails')}
      </Button>
      <Button
        variant="contained"
        color="darkBlue"
        onClick={() => onBtnSubmitClick()}
        endIcon={<ArrowForwardIcon />}
        disabled={disabledControls}
      >
        {t('global.buttons.sendApplication')}
      </Button>
    </ActionButtonsContainer>
  )
}
