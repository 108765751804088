import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { ClientPersonalData, FormTypeSchema } from '@api/endpoints/forms/types'
import { useLanguage } from '@hooks/useLanguage'
import { CommonControlProps } from '@controls/types'
import {
  SelectedVehicleWithCustomerContacts,
  SelectedVehicleWithCustomerContactsProps
} from '@forms/SelectedVehicleWithCustomerContacts'
import { VehicleSelectionMode } from '@pages/types'
import { urlCatalogWithSelectionMode } from '@pages/utils'
import { CustomerDataStep } from './types'
import { useExchangeStore } from './useExchangeStore'

export const ExchangeCustomer: React.FC<CommonControlProps> = ({ disabledControls }) => {
  const navigate = useNavigate()
  const { language } = useLanguage()

  const vehicleId = useExchangeStore((s) => s.carprofVehicleId)
  const activeStep = useExchangeStore((s) => s.activeStep)
  const setVehicleId = useExchangeStore((s) => s.setCarprofVehicleId)
  const setClientData = useExchangeStore((s) => s.setClientData)
  const privacyConsent = useExchangeStore((s) => s.privacyConsent)
  const setPrivacyConsent = useExchangeStore((s) => s.setPrivacyConsent)
  const setNextStep = useExchangeStore((s) => s.setNextStep)

  const handleSubmitSuccess = (data: ClientPersonalData): void => {
    setPrivacyConsent(true)
    setClientData(data)
    setNextStep(CustomerDataStep)
    if (!vehicleId) {
      navigate(urlCatalogWithSelectionMode(VehicleSelectionMode.Exchange, language))
    }
  }

  const selectedVehicleOptions: SelectedVehicleWithCustomerContactsProps['selectedVehicleOptions'] = {
    disabledControls,
    onChooseAnotherClick: () => setVehicleId(undefined)
  }

  const customerContactsOptions: SelectedVehicleWithCustomerContactsProps['customerContactsOptions'] = {
    disabledControls,
    formType: FormTypeSchema.enum.TRADE_IN,
    wizardStep: activeStep,
    defaultValues: useExchangeStore((s) => s.clientData),
    onSubmitSuccess: handleSubmitSuccess,
    consentChecked: privacyConsent
  }

  return (
    <SelectedVehicleWithCustomerContacts
      vehicleId={vehicleId}
      selectedVehicleOptions={selectedVehicleOptions}
      customerContactsOptions={customerContactsOptions}
    />
  )
}
